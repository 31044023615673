<template>
    <v-card width="100%">
        <v-card-title>문항 관리</v-card-title>
        <v-card-actions>
            <v-select :items="trainings"
                      item-value="id" item-text="name"
                      v-model="currentTraining"
                      outlined
                      return-object
                      label="교육과정"
                      @change="$router.push(`/manage/items/${currentTraining.id}`)"
            />
            <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-text>
            <v-layout column>
                <v-row v-for="(day, index) in ['INTRO','DAY1','DAY2','DAY3','DAY4','DAY5','OUTRO']" :key="index" no-gutters class="mt-3">
                    <v-col cols="1" class="day">
                        <div class="label">{{day}}</div>
                    </v-col>
                    <v-col cols="11">
                        <v-layout wrap>
                            <div class="item ma-1" v-for="(item, idx) in items" :key="idx" v-if="item.day === index" @click="editItem(item)" :class="item.day !== 0 ? 'draggable' : ''">
                                <div v-if="item.day === 0" class="fill-height">
                                    <v-layout align-center justify-center fill-height>
                                        <v-img v-if="item.preview" :src="item.preview" width="100%" height="100%" />
                                        <v-icon v-else x-large class="no-img">mdi-help-box</v-icon>
                                    </v-layout>
                                    <div class="item-label">
                                        <div style="height:22px" class="mb-2"></div>
	                                    INTRO
                                    </div>
                                </div>
                                <div v-else class="fill-height">
                                    <v-layout align-center justify-center fill-height>
                                        <v-img v-if="item.preview" :src="item.preview" width="100%" height="100%" />
                                        <v-icon v-else x-large class="no-img">mdi-help-box</v-icon>
                                    </v-layout>
                                    <div class="item-label">
                                        <v-sheet color="transparent" class="item-type mb-2">
                                            [{{ itemTypes[item.type] }}]
                                        </v-sheet>
                                        #{{ item.sequence | leftPad }}
                                    </div>
                                </div>
                                <v-btn v-if="index !== 0" class="btn-delete" icon small color="dark" @click.stop="confirmDelete(item)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                            <div v-if="index !== 0" class="item ma-1 add-item">
                                <v-layout fill-height justify-center align-center
                                          @click="showAddItemDialog(index)"
                                >
                                    <v-icon x-large>mdi-plus</v-icon>
                                </v-layout>
                            </div>
                        </v-layout>
                    </v-col>
                </v-row>
            </v-layout>
        </v-card-text>
        <v-dialog width="300" v-model="showDialog">
            <v-card>
                <v-card-title>문항 추가</v-card-title>
                <v-card-text>
                    <v-select label="문항 유형" v-model="itemType" :items="Object.keys(itemTypes).map((val, key) => { return {value: val, text:itemTypes[val]}})" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="kb-primary" @click="addItem">추가</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
export default {
	data() {
		return {
			trainings: [],
			items: [],
			itemTypes: {
				titleleaf: '간지',
				swipe: '스와이프',
				image: '객관식 이미지',
				text: '객관식 텍스트',
				kakao: '카톡 메시지',
				messenger: '문자 메시지',
			},
            itemType: null,
            showDialog: false,
            day: null,
            currentTraining: {},
		}
	},
    watch: {
		$route(to, from) {
			const id = to.params.id
            this.trainings.forEach(training => {
            	if (training.id == id) {
            		this.currentTraining = training
                }
            })
            this.getList()
        }

    },
	mounted() {
        this.currentTraining.id = this.$route.params.id || this.$cookie.get('training_id')
        this.getTrainings()
	},
    methods: {
		getTrainings() {
			this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training`).then(response => {
				if (response.data.code === '0000') {
					this.trainings = response.data.result

                    if (this.trainings.length > 0) {
                    	if (this.currentTraining.id) {
                    		this.trainings.forEach(training => {
                    			if (training.id == this.currentTraining.id) {
                    				this.currentTraining = training
                                }
                            })
                        } else {
		                    this.currentTraining = this.trainings[0]
	                    }
                    }
					this.getList()
				}
			})
		},
		getList() {
			const params = {
				'training_id': this.currentTraining.id,
            }
			this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/item`, {params: params}).then(response => {
				if (response.data.code === '0000') {
					this.items = response.data.result

                    // if (this.items.length === 0) {
                    // 	this.items.push({
                    //         day: 0,
                    //         training_id: this.currentTraining.id,
                    //     })
                    // }
                }
			})
		},
		async showAddItemDialog(index) {
		    if (index === 0) {
		    	this.itemType = 'intro'
                this.day = 0

                const response = await this.saveItem()
                const item = response.data.result

                await this.$router.push(`/manage/item/${item.id}`)

            } else {
                this.itemType = null
                this.day = index
                this.showDialog = true
            }
        },
        async addItem() {
			if (!this.itemType) {
				this.$store.commit('showDialog', {
					type: 'alert',
                    message: '문항 유형을 선택하세요.'
                })
				return
            }

	        const response = await this.saveItem()
	        const item = response.data.result

	        await this.$router.push(`/manage/item/${item.id}`)

        },
        async saveItem() {
	        const params = {
		        training_id: this.currentTraining.id,
		        type: this.itemType,
		        day: this.day,
	        }
	        const result = await this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/item`, params)
	        return result
        },
        async editItem(item) {
			if (!item.id) {
				this.day = 0
				this.itemType = 'intro'
				const response = await this.saveItem()
                item = response.data.result
            }
	        await this.$router.push(`/manage/item/${item.id}`)
        },
        confirmDelete(item) {
			this.$store.commit('showDialog', {
				type: 'confirm',
                message: `문항을 삭제하시겠습니까?`,
                okCb: _ =>{
	                this.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/item/${item.id}`)
                    .then(response => {
                    	this.$store.commit('showSnackbar', {
		                    message: '삭제했습니다.',
		                    color: 'success',
		                    duration: 3000
                        })

                        this.getList()
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .day {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffcc05;
        border-radius: 10px;
        min-height: 264px;

        .label {
            padding-top: 30px;
            display: flex;
            writing-mode: vertical-lr;
            text-orientation: upright;
            align-items: center;
            font-weight: 600;
        }
    }

    .item {
        position: relative;
        width: 144px;
        height: 256px;
        background: #efefef;
        cursor: pointer;
        border-radius: 10px;
        overflow: hidden;

        .btn-delete {
            position: absolute;
            right: 5px;
            top: 5px;
            font-weight: 600;
            background: rgba(255,255,255, 0.5)
        }

        .no-img {
            color: #ddd;
            font-size: 100px !important;
        }

        &.add-item {
        }

        .item-label {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: linear-gradient(to top, rgba(0,0,0,.7) 0%, rgba(0,0,0,.6) 25%, rgba(0,0,0,.5) 50%, rgba(0,0,0,.3) 70%, rgba(0,0,0,0) 100%);
            padding: 50px 10px 10px;
            color: #ffffff;
            text-align: center;
            font-size: 20px;

            .item-type {
                font-size: 13px;
                color: #efefef;
            }
        }
    }
</style>
