<template>
            <div class="editor mr-10">
                <div class="topnav">
                    <img src="/images/topnav.png" />
                </div>
                <div class="editor-body">
                    <div class="background" @click="backgroundForm(720, 1120)">
                        <img :src="itemData.background_img" width="100%" v-if="itemData.background_img" />
                        <div style="width: 100%; height: 100%; background-color: #ffffff;" v-else />
                    </div>
                    <div class="message">
                        <textarea v-model="itemData.text"
                                  :style="{color: itemData.text_decorations.color,background: itemData.text_decorations.background, textAlign: itemData.text_decorations.align}"
                                  placeholder="텍스트를 입력하세요."
                                  @keydown="resizeBox($event.target)"
                                  @keyup="resizeBox($event.target)"
                                  @focus="textForm(itemData)"
                        />
                    </div>

                    <v-layout class="items" wrap>
                        <div class="item" v-for="(item, index) in itemData.item_answers">
                            <input type="text" v-model="item.text"
                                   placeholder="텍스트를 입력하세요."
                                   :style="{color: item.text_decorations.color,background: item.text_decorations.background, textAlign: item.text_decorations.align}"
                                   @focus="typeForm(item)"
                            />
                            <div class="buttons">
                                <v-btn x-small icon @click="confirmDelete(index)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn x-small icon @click="addItem(index)">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-layout>
                </div>
            </div>
</template>

<script>
export default {
	components: {
    },
	data() {
		return {
			form: '',
            color: '#efefef',
            itemData: {
	            text_decorations: {background: '', color: ''},
	            item_answers: [
		            { text_decorations: { background: '', color: ''}},
		            { text_decorations: { background: '', color: ''}},
		            { text_decorations: { background: '', color: ''}},
		            { text_decorations: { background: '', color: ''}},
	            ]
            },
			currentItem: {},
        }
    },

    props: [
    	'item'
    ],

    watch: {
		item(val) {
			if (val) {
				if (val.text_decorations) {
					if (typeof val.text_decorations === 'string') {
                        val.text_decorations = JSON.parse(val.text_decorations)
					}
				} else {
					val.text_decorations = { background: '', color: ''}
				}

				if (val.item_answers && val.item_answers.length) {
					val.item_answers.forEach(item => {
						if (item.text_decorations && typeof item.text_decorations === 'string') {
							item.text_decorations = JSON.parse(item.text_decorations)
						}
					})
				} else {
					val.item_answers = [
						{ text_decorations: { background: '', color: ''}},
						{ text_decorations: { background: '', color: ''}},
						{ text_decorations: { background: '', color: ''}},
						{ text_decorations: { background: '', color: ''}},
					]
				}
				this.itemData = val

				setTimeout(_ => {
					$('textarea').each((idx, el)=> {
						this.resizeBox(el)
					})
				})
			}
		}
    },

	mounted() {
		if (this.item) {
			console.log(typeof this.item.text_decorations)
			if (this.item.text_decorations) {
				if (typeof this.item.text_decorations === 'string') {
                    this.item.text_decorations = JSON.parse(this.item.text_decorations)
				}
			} else {
				this.item.text_decorations = { background: '', color: ''}
			}

			if (this.item.item_answers && this.item.item_answers.length) {
				this.item.item_answers.forEach(item => {
					if (item.text_decorations && typeof item.text_decorations === 'string') {
						item.text_decorations = JSON.parse(item.text_decorations)
					}
				})
			} else {
				this.item.item_answers = [
					{ text_decorations: { background: '', color: ''}},
					{ text_decorations: { background: '', color: ''}},
					{ text_decorations: { background: '', color: ''}},
					{ text_decorations: { background: '', color: ''}},
				]
			}
			this.itemData = this.item

			setTimeout(_ => {
				$('textarea').each((idx, el)=> {
					this.resizeBox(el)
				})
			})

		}
	},

	methods: {
		resizeBox(el) {
			el.style.scrollTop = 0
			el.style.height = 'auto'
			el.style.height = (el.scrollHeight) + 'px'
		},
		backgroundForm(width, height) {
	        this.$emit('click:background', width, height)
        },
		typeForm(itemData) {
			this.$emit('click:type', itemData)
		},
		textForm(itemData) {
            this.$emit('click:text', itemData)
        },
		addItem(index) {
			console.log(index)
			this.itemData.item_answers.splice(index + 1, 0, { text_decorations: { background: '', color: ''}})

        },
        confirmDelete(index) {
	        console.log(index)
			this.$store.commit('showDialog', {
				type: 'confirm',
                message: '선택지를 삭제하시겠습니까?',
                okCb: _ => {
	                this.itemData.item_answers.splice(index, 1)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.editor {
    width: 360px;
    height: 640px;
    border: 1px solid #ccc;
    position: relative;

    .spliter, .left, .right {
        pointer-events: none;
    }

    .topnav {
        width: 100%;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 50;

        img {
            max-width: 100%;
        }
    }

    .editor-body {
        width: 100%; height: 100%;
        overflow: hidden;

        .background {
            position: absolute;
            top: 87px; left: 0;
            width: 100%;
            height: calc(100% - 87px);
        }
        .background:hover {
            opacity: 0.9;
            cursor: pointer;
            border: 1px solid #ccc;
            margin: -1px;
            width: calc(100% + 2px);
            height: calc(100% - 85px);
        }

        textarea {
            width: 100%;
            height: 100%;
            resize: none;
            padding: 2px;
            overflow-y: hidden;
            pointer-events: auto;
            margin: -1px;
            border: 1px dashed rgba(128,128,128,.5);
        }
        textarea:hover, textarea:focus {
            background: rgba(255,255,255,.2);
            border: 1px solid #ccc;
            margin: -1px;
            border-radius: 5px;
        }
        .message {
            position: absolute;
            top: 128.5px;
            left: 41.1px;
            right: 41.1px;
            font-size: 17px;

            textarea {
            }
        }

        .items {
            position: absolute;
            top: 260px;
            left: 30.5px;
            right: 35px;

            .item {
                width: 100%;
                height: 52px;
                border-radius: 26px;
                box-shadow: 5px 5px 5px 0 rgba(15, 41, 107, 0.12);
                border: solid 1px rgba(255, 255, 255, 0.5);
                background-image: linear-gradient(100deg, #e8ebf2, #f2f3f7);
                position: relative;
                margin-bottom: 21.5px;

                input {
                    font-size: 17px;
                    padding: 14px;
                    width: 100%;
                    height: 100%;
                    border-radius: 26px;
                    text-align: center;
                    border: 1px dashed rgba(128,128,128,.5);
                }

                .buttons {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                }

                &:hover {
                    background-image: linear-gradient(137deg, #f5f2ea 3%, #fffef5 99%);
                }
            }
        }
    }
}
</style>
