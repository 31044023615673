<template>
            <div class="editor mr-10">
                <div class="topnav">
                    <img src="/images/topnav.png" />
                </div>
                <div class="editor-body">
                    <div class="background" @click="backgroundForm(720, 1120)">
                        <img :src="itemData.background_img" width="100%" v-if="itemData.background_img" />
                        <div style="width: 100%; height: 100%; background-color: #efefef;" v-else />
                    </div>
                    <div class="message">
                        <textarea v-model="itemData.text"
                                  :style="{color: itemData.text_decorations.color,background: itemData.text_decorations.background, textAlign: itemData.text_decorations.align}"
                                  placeholder="텍스트를 입력하세요."
                                  @keydown="resizeBox($event.target)"
                                  @keyup="resizeBox($event.target)"
                                  @focus="textForm(itemData)"
                        />
                    </div>
                    <div class="swipe" v-if="itemData.action_type === 'swipe'">
                        <div>
                            <img src="/images/swipe_right.png" />
                        </div>
                        <div>
                        <textarea v-model="itemData.message"
                                  :style="{color: itemData.message_decorations.color,background: itemData.message_decorations.background, textAlign: itemData.message_decorations.align}"
                                  placeholder="텍스트를 입력하세요."
                                  @keydown="resizeBox($event.target)"
                                  @keyup="resizeBox($event.target)"
                                  @focus="textForm(itemData, false, true)"
                        />
                        </div>
                    </div>
                    <div class="btn-start" v-else>
                        <input type="text" v-model="item.message"
                               placeholder="텍스트를 입력하세요."
                               @focus="$emit('click:clear')"
                        />
                    </div>
                </div>
            </div>
</template>

<script>
export default {
	components: {
    },
	data() {
		return {
			form: '',
            color: '#efefef',
            itemData: {
	            text_decorations: {background: '', color: ''},
            },
			currentItem: {},
        }
    },

    props: [
    	'item'
    ],

    watch: {
    },

	mounted() {
		if (this.item) {
			if (this.item.text_decorations && typeof this.item.text_decorations === 'string') {
				this.item.text_decorations = JSON.parse(this.item.text_decorations)
			} else {
				this.item.text_decorations = { background: '', color: ''}
			}
			if (this.item.message_decorations && typeof this.item.message_decorations === 'string') {
				this.item.message_decorations = JSON.parse(this.item.message_decorations)
			} else {
				this.item.message_decorations = { background: '', color: ''}
			}
			if (!this.item.action_type) this.item.action_type = 'button'
			this.itemData = this.item

			setTimeout(_ => {
				$('textarea').each((idx, el)=> {
					this.resizeBox(el)
				})
			})

		}
	},

	methods: {
		resizeBox(el) {
			el.style.scrollTop = 0
			el.style.height = 'auto'
			el.style.height = (el.scrollHeight) + 'px'
		},
		backgroundForm(width, height) {
	        this.$emit('click:background', width, height)
        },
        textForm(itemData, withType, isMessage) {
            this.$emit('click:text', itemData, withType, isMessage)
        },
    }
}
</script>

<style lang="scss" scoped>
.editor {
    width: 360px;
    height: 640px;
    border: 1px solid #ccc;
    position: relative;

    .spliter, .left, .right {
        pointer-events: none;
    }

    .topnav {
        width: 100%;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 50;

        img {
            max-width: 100%;
        }
    }

    .editor-body {
        width: 100%; height: 100%;
        overflow: hidden;

        .background {
            position: absolute;
            top: 87px; left: 0;
            width: 100%;
            height: calc(100% - 87px);
        }
        .background:hover {
            opacity: 0.9;
            cursor: pointer;
            border: 1px solid #ccc;
            margin: -1px;
            width: calc(100% + 2px);
            height: calc(100% - 85px);
        }

        textarea {
            width: 100%;
            height: 100%;
            resize: none;
            padding: 2px;
            overflow-y: hidden;
            pointer-events: auto;
            margin: -1px;
            border: 1px dashed rgba(128,128,128,.5);
        }
        textarea:hover, textarea:focus {
            background: rgba(255,255,255,.2);
            border: 1px solid #ccc;
            margin: -1px;
            border-radius: 5px;
        }

        .message {
            position: absolute;
            top: 126.5px;
            left: 65px;
            right: 65px;
            /*background: rgba(255,255,255,.3);*/
            font-size: 17px;

            textarea {
                text-align: center;
            }
        }
        .swipe {
            pointer-events: none;

            position: absolute;
            right: 35px;
            top: 492px;
            text-align: right;

            img {
                width: 61px;
            }

            textarea {
                width: 200px;
                height: 74px;
                font-size: 17px;
                margin-top: 16px;
                text-align: right;
            }
        }

        .btn-start {
            position: absolute;
            left: 77.5px;
            bottom: 15px;
            width: 191px;
            height: 46px;
            border-radius: 28px;
            box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.16);
            background-image: linear-gradient(109deg, #f9cb51 10%, #ffb900 102%);

            input {
                width: 100%;
                height: 100%;
                font-size: 17px;
                border-radius: 28px;
                text-align: center;
                color: #fff;
                border: 1px dashed rgba(128,128,128,.5);
            }
            input:hover, input:focus {
                background: rgba(255,255,255,.2);
                border: 1px solid #ccc;
            }
        }
    }
}
</style>
