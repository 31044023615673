<template>
    <v-main class="fill-height pt-16">
        <v-app-bar color="default" flat fixed>
<!--            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>-->
            <v-toolbar-title class="app-title"><v-img src="/images/logo.png" max-height="40" contain /></v-toolbar-title>
            <v-spacer></v-spacer>
            <button class="btn btn-primary" style="width:170px" @click="moveTo('/training')">교육과정 추가/선택</button>
            <button class="btn btn-primary ml-3" style="width:170px" @click="moveTo('/results')">전체과정 결과</button>
            <v-spacer></v-spacer>
            <div class="btn-group">
                <button type="button" class="btn btn-primary mr-3 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                수정/관리
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item" @click="moveTo('/manage/items')">문항</a>
                    <a class="dropdown-item" @click="moveTo('/manage/audio')">효과음</a>
                    <a class="dropdown-item" @click="moveTo('/manage/result/image')">결과 이미지</a>
                    <a class="dropdown-item" @click="moveTo('/manage/result/pdf')">결과 PDF</a>
                    <a class="dropdown-item" @click="moveTo('/manage/email/template')">이메일 템플릿</a>
                </div>
            </div>

            <button class="btn btn-secondary" @click="logout()">로그아웃</button>
        </v-app-bar>

        <v-layout fill-height class="pt-6 pb-6">

            <router-view name="main" class="main"></router-view>

        </v-layout>
    </v-main>
</template>
<script>
    export default {
    	data() {
    		return {
			    drawer: false,
			    group: null,
            }
        },
        mounted() {
    		this.$eventBus.$on('MOVE_TO', this.moveTo)
        },
	    beforeCreate() {
		    if (!this.$cookie.get('token')) {
		    	this.$router.replace('/')
		    }
	    },
        methods: {
    		logout() {
    			this.$cookie.delete('training_id')
                this.$cookie.delete('token')
                this.$router.push('/')
            },
            moveTo(path) {
	            if (this.$route.path !== path) this.$router.push(path)
            }
        }
    }
</script>
<style scoped>
    .app-title {
        font-family: KBFGDisplayB;
        color: rgba(0, 0, 0, 0.54) !important;
    }

    .main {
        max-width: 1280px;
        margin: 0 auto;
    }
</style>

<style>
    .btn-primary {
        color: #60584c !important;
        border: 2px solid #60584C !important;
        background-color: #ffbc00 !important;
    }
    .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
        color: #fff !important;
        background-color: #60584C !important;
        border: 2px solid #60584C !important;

    }
    .btn-secondary {
        color: #ffffff !important;
        border: 2px solid #60584C!important;
        background-color: #60584c !important;
    }
    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .open>.dropdown-toggle.btn-secondary {
        color: #fff !important;
        background-color: #464038 !important;
        border: 2px solid #464038 !important;

    }
    .btn:focus,.btn:active {
        outline: none !important;
        box-shadow: 0px 0px 1px 2px #a59a8f !important;
    }
    .btn {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }
    .btn:focus i, .btn:active i, .btn:hover i {
        color: #ffffff !important;
    }

    .dropdown-menu {
        margin-top: 0px !important;
        border: 2px solid #464038 !important;
    }
    .dropdown-menu > a:hover {
        background-image: none;
        background-color: #464038;
        color: #fff;
    }
</style>
